import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Tenants
  {
    path: '/tenants',
    name: 'Tenants',
    component: () => import('../views/Tenants.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/properties-for-rent',
    name: 'Properties For Rent',
    component: () => import('../views/PropertiesForRent.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/queensland',
    name: 'Queensland Forms',
    component: () => import('../views/QueenslandForms.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/queensland/tenancy-agreement',
    name: 'Tenancy Agreement',
    component: () => import('../views/Queensland/TenancyAgreement.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/queensland/entry-condition-report',
    name: 'Entry Condition Report',
    component: () => import('../views/Queensland/EntryConditionReport.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/queensland/maintenance-request',
    name: 'Maintenance Request',
    component: () => import('../views/Queensland/MaintenanceRequest.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/queensland/notice-of-leave',
    name: 'Notice Of Leave',
    component: () => import('../views/Queensland/NoticeOfLeave.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/queensland/notice-of-leave',
    name: 'Notice Of Leave',
    component: () => import('../views/Queensland/NoticeOfLeave.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/queensland/exit-condition-report',
    name: 'Exit Condition Report',
    component: () => import('../views/Queensland/ExitConditionReport.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/queensland/appointment-of-property-agent',
    name: 'Appointment Of Property Agent',
    component: () => import('../views/Queensland/AppointmentOrReappointmentOfAPropertyAgent.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/new-south-wales',
    name: 'New South Wales Forms',
    component: () => import('../views/NewSouthWalesForms.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/new-south-wales/tenancy-agreement',
    name: 'Tenancy Agreement',
    component: () => import('../views/NewSouthWales/TenancyAgreement.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/new-south-wales/condition-report',
    name: 'Condition Report',
    component: () => import('../views/NewSouthWales/ConditionReport.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/new-south-wales/maintenance-request',
    name: 'Maintenance Request',
    component: () => import('../views/Queensland/MaintenanceRequest.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/forms/new-south-wales/notice-of-leave',
    name: 'Notice Of Leave',
    component: () => import('../views/NewSouthWales/NoticeOfLeave.vue'),
    meta: {
      hideAncillaries: true,
    },
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
